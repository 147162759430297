import * as React from "react";
import MarkerClusterer from "react-google-maps/lib/components/addons/MarkerClusterer";
import { Marker } from "react-google-maps";
import { Utils } from "../Utils/Utils";
import { SlidingPaneInfo } from "./InfoWindowComponents/SlidingPaneInfo";
import { InfoWindowCustom } from "./InfoWindowComponents/InfoWindowCustom";
import "./CSS/marker.css";

interface IProps {
	markers: any[];
}

interface IState {
	markers: any[];
	isOpenId: number;
}

export class ClusterMarkersComponent extends React.Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);

		this.state = {
			markers: props.markers,
			isOpenId: 0,
		};
	}

	public componentWillReceiveProps(props: IProps) {
		this.setState({
			markers: props.markers,
		});
	}

	public render() {
		return (
			<>{this._markers()}</>
			// <MarkerClusterer
			// 	styles={[
			// 		{
			// 			height: 53,
			// 			url: "https://raw.githubusercontent.com/googlemaps/v3-utility-library/master/packages/markerclustererplus/images/m1.png",
			// 			width: 53,
			// 		},
			// 		{
			// 			height: 56,
			// 			url: "https://raw.githubusercontent.com/googlemaps/v3-utility-library/master/packages/markerclustererplus/images/m2.png",
			// 			width: 56,
			// 		},
			// 		{
			// 			height: 66,
			// 			url: "https://raw.githubusercontent.com/googlemaps/v3-utility-library/master/packages/markerclustererplus/images/m3.png",
			// 			width: 66,
			// 		},
			// 		{
			// 			height: 78,
			// 			url: "https://raw.githubusercontent.com/googlemaps/v3-utility-library/master/packages/markerclustererplus/images/m4.png",
			// 			width: 78,
			// 		},
			// 		{
			// 			height: 90,
			// 			url: "https://raw.githubusercontent.com/googlemaps/v3-utility-library/master/packages/markerclustererplus/images/m5.png",
			// 			width: 90,
			// 		},
			// 	]}
			// 	gridSize={25}
			// >
			// 	{this._markers()}
			// </MarkerClusterer>
		);
	}

	private _markers = () => {
		return (
			<div className="marker">
				{this.state.markers.map((marker) => {
					let iconUrl: string =
						"http://cityair-maps.bch-isdp.ro/markersPhotos/" + marker.position + "/";
					//iconUrl += marker.airQuality.title + ".svg";
					switch (marker.airQuality != null ? marker.airQuality.title : "") {
						case "Buna": {
							iconUrl += "green.svg";
							break;
						}
						case "Medie": {
							iconUrl += "orange.svg";
							break;
						}
						case "Rea": {
							iconUrl += "red.svg";
							break;
						}
					}

					if (marker.location.lat != null && marker.location.lng != null) {
						return (
							<Marker
								icon={iconUrl}
								position={{
									lat: marker.location.lat,
									lng: marker.location.lng,
								}}
								onClick={() => {
									this.setState({ isOpenId: marker.id });
								}}
								onMouseOver={() => {
									if (!Utils.getIsMobile()) {
										this.setState({ isOpenId: marker.id });
									}
								}}
								// onMouseOut={this._closeCallback}
							>
								{this.state.isOpenId == marker.id &&
									this._markerInformation(marker)}
							</Marker>
						);
					}
				})}
			</div>
		);
	};

	private _markerInformation = (marker: any) => {
		if (Utils.getIsMobile()) {
			return (
				<SlidingPaneInfo marker={marker} closeCallback={this._closeCallback} />
			);
		} else {
			return (
				<InfoWindowCustom marker={marker} closeCallback={this._closeCallback} />
			);
		}
	};

	private _closeCallback = () => {
		this.setState({ isOpenId: 0 });
	};
}
