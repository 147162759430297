import * as React from "react";
import { Map } from "./MapComponent";
import { ListComponent } from "./ListComponent";
import { List } from "office-ui-fabric-react/lib/components/List/List";
import { Label } from "office-ui-fabric-react/lib/Label";
import { Checkbox } from "office-ui-fabric-react/lib/components/Checkbox/Checkbox";
import { Header } from "./HeaderComponent";
import { Icon } from "office-ui-fabric-react/lib/Icon";
import { noWrap, TooltipHost } from "office-ui-fabric-react";
import "./CSS/legend.css";
import "./CSS/snackbar.css";
import { Utils } from "../Utils/Utils";
// @ts-ignore
import SlidingPane from "react-sliding-pane";
import { Authentication } from "./Authentication";
import { WindMap } from "./WindMapComponent";
const request = require("request");

interface IFilter {
	key: string;
	property: string;
	name: string;
	interval: any;
	dates: any;
	list: any;
}

interface IProps {
	body: any;
}

interface IState {
	mode: number;
	categories: any[];
	markers: any[];

	filters: IFilter[];

	filtersData: any[];

	isMenuOpen: boolean;

	zoom: number;
	lng: number;
	lat: number;
	kmlUrl: string;

	hideAuth: boolean;
	sensorsTypes: any;
}

let _map: any;

export class MainComponent extends React.Component<IProps, IState> {
	private _markers: any[] = [];

	public static allowedFilters: any[] = [];

	private _refFiltersList: any;
	private _refCategoriesList: any;
	private _refSensorsTypesList: any;
	private _refHeader: any;

	private _sensorsTypes = ["Interior", "Exterior"];

	constructor(props: IProps) {
		super(props);

		if (
			/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
				navigator.userAgent
			) ||
			window.innerWidth < 800
		) {
			console.log("Mobil");
			Utils.setIsMobile(true);
		} else {
			console.log("NeMobil");
			Utils.setIsMobile(false);
		}

		let categorii;
		let filtersData;
		let body = this.props.body;

		console.log("Body", body);

		categorii = body.categories;
		filtersData = body.filters;

		if (filtersData != undefined) {
			for (let i = 0; i < filtersData.length; i++) {
				MainComponent.allowedFilters[filtersData[i].property] =
					filtersData[i].showInPinWindow;
			}
		}

		let id = 1;

		for (let i = 0; i < categorii.length; i++) {
			categorii[i].selected = "true";

			for (let j = 0; j < categorii[i].elements.length; j++) {
				categorii[i].elements[j].iconURL = categorii[i].iconURL;
				categorii[i].elements[j].id = id;
				id++;

				this._markers.push(categorii[i].elements[j]);
			}
		}

		console.log("Categories", categorii);
		console.log("Filters", filtersData);
		console.log("Markers", this._markers);

		this.state = {
			mode: 1,
			categories: categorii,
			markers: this._markers,
			filters: [],
			filtersData: filtersData,
			isMenuOpen: false,
			zoom: body.zoom,
			lat: body.lat,
			lng: body.lng,
			kmlUrl: body.boundariesFileURL,
			hideAuth: true,
			sensorsTypes: this._sensorsTypes,
		};
	}

	public refreshComponent = () => {
		var options = {
			method: "GET",
			url: Utils.getURL() + "Pin/GetPins",
		};

		request(options, (error: any, response: any, newBody: any) => {
			if (error) throw new Error(error);

			this._markers = [];
			MainComponent.allowedFilters = [];

			let categorii;
			let filtersData;
			let body = JSON.parse(newBody);

			console.log("New Body", body);

			categorii = body.categories;
			filtersData = body.filters;

			if (filtersData != undefined) {
				for (let i = 0; i < filtersData.length; i++) {
					MainComponent.allowedFilters[filtersData[i].property] =
						filtersData[i].showInPinWindow;
				}
			}

			let id = 1;

			for (let i = 0; i < categorii.length; i++) {
				categorii[i].selected = "true";

				for (let j = 0; j < categorii[i].elements.length; j++) {
					categorii[i].elements[j].iconURL = categorii[i].iconURL;
					categorii[i].elements[j].id = id;
					id++;

					this._markers.push(categorii[i].elements[j]);
				}
			}

			this.setState({
				mode: 1,
				categories: categorii,
				markers: this._markers,
				filters: [],
				filtersData: filtersData,
				isMenuOpen: false,
				zoom: body.zoom,
				lat: body.lat,
				lng: body.lng,
				kmlUrl: body.boundariesFileURL,
				hideAuth: true,
			});
		});
	};

	public render() {
		return (
			<div className="App">
				<Header
					ref={(header) => (this._refHeader = header)}
					filtersData={this.state.filtersData}
					changeMode={this._changeMode}
					addIntervalFilter={this._addIntervalFilter}
					addListFilter={this._addListFilter}
					addSearchFilter={this._addSearchFilter}
					addDateFilter={this._addDateFilter}
					openMenu={this._openMenu}
					filtersNo={this.state.filters.length}
					showAuth={this.showDialog}
					markersNo={this.state.markers.length}
				/>

				<Authentication
					hideDialog={this.state.hideAuth}
					closeCallback={this.hideDialog}
				/>

				<div id="snackbar" />

				{(this.state.mode == 1 || this.state.mode == 2) && this._legend()}

				{(this.state.mode == 1 || this.state.mode == 2) &&
					!Utils.getIsMobile() &&
					this._legendColors()}

				<div
					style={{
						display: this.state.mode == 2 ? "" : "none",
						marginLeft: Utils.getIsMobile() ? "" : "250px",
						marginRight: Utils.getIsMobile() ? "" : "20px",
					}}
				>
					<ListComponent
						markers={this.state.markers}
						filters={this.state.filtersData}
					/>
				</div>
				<div
					style={{
						display: this.state.mode == 1 ? "" : "none",
					}}
				>
					<Map
						ref={(map) => (_map = map)}
						markers={this.state.markers}
						zoom={this.state.zoom}
						lat={this.state.lat}
						lng={this.state.lng}
						kmlUrl={this.state.kmlUrl}
						refreshComponent={this.refreshComponent}
					/>
				</div>
				{/* <div
					style={{
						display: this.state.mode == 3 ? "" : "none",
					}}
				>
					<WindMap />
				</div> */}
			</div>
		);
	}

	private _legend = () => {
		if (Utils.getIsMobile()) {
			return (
				<SlidingPane
					isOpen={this.state.isMenuOpen}
					from="right"
					width="80%"
					onRequestClose={this._closeMenu}
					onAfterOpen={() => {
						document
							.getElementsByClassName("slide-pane_from_right")[0]
							.setAttribute("style", `height: ${window.innerHeight}px`);
					}}
				>
					<Icon
						style={{ float: "right" }}
						iconName="ChromeClose"
						onClick={this._closeMenu}
					/>
					<Label
						style={{
							marginBottom: "20px",
							textAlign: "center",
							fontSize: 20,
							clear: "both",
						}}
					>
						LEGENDA
					</Label>

					<div style={{ marginBottom: 10, border: "1px solid" }}>
						<Label style={{ padding: 5 }}>Categorii:</Label>
						<List
							//style={{ maxWidth: 200 }}
							ref={(list) => (this._refCategoriesList = list)}
							items={this.state.categories}
							onRenderCell={this._onRenderCategoryCell}
						/>
					</div>

					<div style={{ marginBottom: 10, border: "1px solid" }}>
						<Label style={{ padding: 5 }}>Legenda:</Label>
						<List
							ref={(list) => (this._refSensorsTypesList = list)}
							items={this.state.sensorsTypes}
							onRenderCell={this._onRenderSensorCell}
						/>
					</div>

					<div style={{ marginBottom: 10, border: "1px solid" }}>
						<Label style={{ padding: 5 }}>Legenda Culori:</Label>
						<div
							style={{
								backgroundColor: "red",
								textAlign: "center",
							}}
						>
							Bun
						</div>
						<div
							style={{
								backgroundColor: "blue",
								textAlign: "center",
							}}
						>
							Acceptabil
						</div>
						<div
							style={{
								backgroundColor: "green",
								textAlign: "center",
							}}
						>
							Moderat
						</div>
						<div
							style={{
								backgroundColor: "red",
								textAlign: "center",
							}}
						>
							Rau
						</div>
						<div
							style={{
								backgroundColor: "pink",
								textAlign: "center",
							}}
						>
							Foarte Rau
						</div>
						<div
							style={{
								backgroundColor: "yellow",

								textAlign: "center",
							}}
						>
							Extrem de rau
						</div>
					</div>

					<div
						style={{
							marginBottom: 10,
							display: this.state.filters.length > 0 ? "" : "none",
							border: "1px solid",
						}}
					>
						<Label style={{ padding: 5 }}>Filtre:</Label>
						<List
							style={{ maxWidth: 200 }}
							ref={(list) => (this._refFiltersList = list)}
							items={this.state.filters}
							onRenderCell={this._onRenderFilterCell}
						/>
					</div>
				</SlidingPane>
			);
		} else {
			return (
				<div
					style={{
						padding: 5,
						backgroundColor: "white",
						float: "left",
						marginLeft: this.state.mode == 1 ? "20px" : "10px",
						marginRight: this.state.mode == 1 ? "20px" : "10px",
						position: this.state.mode == 1 ? "absolute" : "initial",
						marginTop: this.state.mode == 1 ? "110px" : "10px",
						zIndex: 99,
						boxShadow:
							"0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
						borderRadius: 5,
					}}
				>
					<div className={"legend"} style={{ marginBottom: 10 }}>
						<Label style={{ padding: 5 }}>Categorii:</Label>
						<List
							//style={{ maxWidth: 200 }}
							ref={(list) => (this._refCategoriesList = list)}
							items={this.state.categories}
							onRenderCell={this._onRenderCategoryCell}
						/>
					</div>

					<div className={"legend"} style={{ marginBottom: 10 }}>
						<Label style={{ padding: 5 }}>Legenda:</Label>
						<List
							ref={(list) => (this._refSensorsTypesList = list)}
							items={this.state.sensorsTypes}
							onRenderCell={this._onRenderSensorCell}
						/>
					</div>

					<div
						style={{
							marginBottom: 10,
							display: this.state.filters.length > 0 ? "" : "none",
						}}
					>
						<Label style={{ padding: 5 }}>Filtre:</Label>
						<List
							style={{ maxWidth: 200 }}
							ref={(list) => (this._refFiltersList = list)}
							items={this.state.filters}
							onRenderCell={this._onRenderFilterCell}
						/>
					</div>
				</div>
			);
		}
	};

	private _legendColors = () => {
		let offsetRight = 70;
		let offsetTop = 120;

		let availableHeight = window.innerHeight;
		let availableWidth = window.innerWidth;

		let legendWidth = 780;
		while (legendWidth + 60 >= availableWidth) {
			legendWidth -= 60;
		}

		let tileWidth = legendWidth / 6;

		let marginLeft = availableWidth - legendWidth - offsetRight;
		let marginTop = availableHeight - offsetTop;

		return (
			<div
				style={{
					backgroundColor: "white",
					float: "right",
					marginLeft: marginLeft + "px",
					marginRight: offsetRight + "px",
					position: "absolute",
					marginTop: marginTop + "px",
					zIndex: 99,
					borderRadius: 5,
					boxShadow:
						"0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",

					width: legendWidth + "px",
					whiteSpace: "nowrap",
					height: "25px",
				}}
				className="row"
			>
				<div
					style={{
						backgroundColor: "#35f500",
						width: tileWidth + "px",
					}}
					className="col"
				>
					Bun
				</div>
				<div
					style={{ backgroundColor: "#00bf49", width: tileWidth + "px" }}
					className="col"
				>
					Acceptabil
				</div>
				<div
					style={{ backgroundColor: "#9fc932", width: tileWidth + "px" }}
					className="col"
				>
					Moderat
				</div>
				<div
					style={{ backgroundColor: "#d15454", width: tileWidth + "px" }}
					className="col"
				>
					Rau
				</div>
				<div
					style={{ backgroundColor: "#9e0505", width: tileWidth + "px" }}
					className="col"
				>
					Foarte Rau
				</div>
				<div
					style={{
						backgroundColor: "#852485",
						width: tileWidth + "px",
					}}
					className="col"
				>
					Extrem de rau
				</div>
			</div>
		);
	};

	private _openMenu = () => {
		this.setState({ isMenuOpen: !this.state.isMenuOpen });
	};

	private _closeMenu = () => {
		this.setState({ isMenuOpen: false });
	};

	private _onRenderSensorCell = (
		item: any,
		index: number | undefined
	): JSX.Element => {
		return (
			<div
				style={{
					margin: 10,
					marginBottom: 25,
					paddingTop: 5,
					flexGrow: 1,
					// borderBottom: "1px solid",
					// borderTop: "1px solid",
					height: 30,
				}}
			>
				<div style={{ float: "left", marginRight: "5px" }}>
					<img
						alt=""
						style={{ width: 25, height: 30 }}
						src={"http://cityair-maps.bch-isdp.ro/markersPhotos/" + item + ".png"}
					/>
				</div>
				{item}
				&nbsp;&nbsp;&nbsp;&nbsp;
			</div>
		);
	};

	private _onRenderCategoryCell = (
		item: any,
		index: number | undefined
	): JSX.Element => {
		return (
			<div
				style={{
					margin: 10,
					marginBottom: 25,
					paddingTop: 5,
					flexGrow: 1,
					// borderBottom: "1px solid",
					// borderTop: "1px solid",
					height: 30,
				}}
			>
				<div style={{ float: "right", marginLeft: 5 }}>
					<Checkbox
						checked={item.selected == "true"}
						onChange={(
							ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
							checked?: boolean
						) => {
							let categories = this.state.categories;
							for (let i = 0; i < categories.length; i++) {
								if (categories[i].id == item.id && checked != undefined) {
									if (checked) {
										categories[i].selected = "true";
									} else {
										categories[i].selected = "false";
									}
								}
							}

							this.setState({ categories: categories }, () => {
								this._determineValidMarkers();
								this._refresh();
							});
						}}
					/>
				</div>
				{/* <div style={{ float: "left" }}>
					<img style={{ width: 25, height: 25 }} src={item.iconURL} />
				</div> */}
				{item.name}
				&nbsp;&nbsp;&nbsp;&nbsp;
			</div>
		);
	};

	private _onRenderFilterCell = (
		item: any,
		index: number | undefined
	): JSX.Element => {
		return (
			<div
				style={{
					margin: 10,
					paddingTop: 5,
					borderBottom: "1px solid",
					// borderTop: "1px solid"
					overflow: "hidden",
					textOverflow: "ellipsis",
					whiteSpace: "nowrap",
				}}
			>
				<TooltipHost
					content={item.name}
					calloutProps={{ gapSpace: 0 }}
					styles={{
						root: {
							display: "inline-block",
						},
					}}
				>
					<Label
						style={{
							float: "left",
							width: 150,
							overflow: "hidden",
							textOverflow: "ellipsis",
							whiteSpace: "nowrap",
						}}
					>
						{" "}
						{item.name}{" "}
					</Label>
				</TooltipHost>

				<div
					style={{
						float: "right",
					}}
				>
					<Icon
						iconName="Cancel"
						style={{ color: "red", cursor: "pointer" }}
						onClick={() => {
							if (index != undefined) {
								let categories = this.state.categories;
								for (let i = 0; i < categories.length; i++) {
									categories[i].selected = "true";
								}

								const filters = this.state.filters;

								if (filters[index].key == "Search") {
									this._refHeader.setState({
										searchValue: "",
									});
								}

								filters.splice(index, 1);

								this.setState({ filters: filters }, () => {
									this._determineValidMarkers();
									this._refresh();
								});
							}
						}}
					/>
				</div>
			</div>
		);
	};

	private _determineValidMarkers = () => {
		let markers: any[] = [];
		let categories = this.state.categories;
		let ok: boolean;

		for (let i = 0; i < categories.length; i++) {
			if (categories[i].selected == "true") {
				ok = false;
				for (let j = 0; j < categories[i].elements.length; j++) {
					if (this._isMarkerValid(categories[i].elements[j])) {
						markers.push(categories[i].elements[j]);
						ok = true;
					}
				}
				if (!ok) {
					categories[i].selected = "false";
				}
			}
		}

		this.setState({ markers: markers });
	};

	private _isMarkerValid = (marker: any): boolean => {
		let filters = this.state.filters;

		for (let filter of filters) {
			if (filter.key == "Search") {
				if (!marker.title.toLowerCase().includes(filter.property)) {
					return false;
				}
			} else {
				if (filter.interval != null) {
					for (let i = 0; i < marker.FilterValues.PinFilterValues.length; i++) {
						if (
							filter.property ==
							marker.FilterValues.PinFilterValues[i].filterProperty
						) {
							if (
								marker.FilterValues.PinFilterValues[i].value <
									filter.interval.min ||
								marker.FilterValues.PinFilterValues[i].value >
									filter.interval.max
							) {
								return false;
							}
						}
					}
				}
				if (filter.list != null) {
					let ok: boolean = false;

					for (let i = 0; i < marker.FilterValues.PinFilterValues.length; i++) {
						if (
							filter.property ==
							marker.FilterValues.PinFilterValues[i].filterProperty
						) {
							for (let listItem of filter.list) {
								if (listItem == marker.FilterValues.PinFilterValues[i].key) {
									ok = true;
								}
							}
						}
					}

					if (!ok) {
						return false;
					}
				}
				if (filter.dates != null) {
					for (let i = 0; i < marker.FilterValues.PinFilterValues.length; i++) {
						if (
							filter.property ==
							marker.FilterValues.PinFilterValues[i].filterProperty
						) {
							// console.log(
							//   marker.FilterValues.PinFilterValues[i].value,
							//   filter.dates.start,
							//   filter.dates.end
							// );

							// let current = new Date(
							//   marker.FilterValues.PinFilterValues[i].value.replace(
							//     /(\d+)\/(\d+)\/(\d+)/,
							//     "$3/$2/$1"
							//   )
							// );
							// let start = new Date(
							//   filter.dates.start.replace(/(\d+)\/(\d+)\/(\d+)/, "$3/$2/$1")
							// );
							// let end = new Date(
							//   filter.dates.end.replace(/(\d+)\/(\d+)\/(\d+)/, "$3/$2/$1")
							// );

							if (
								marker.FilterValues.PinFilterValues[i].value <
									filter.dates.start ||
								marker.FilterValues.PinFilterValues[i].value > filter.dates.end
							) {
								return false;
							}
						}
					}
				}
			}
		}

		return true;
	};

	private _changeMode = (mode: number) => {
		this.setState({ mode: mode });

		if (mode == 1 && _map != null) {
			_map.forceUpdate();
		}
	};

	private _addSearchFilter = (query: string) => {
		let categories = this.state.categories;
		for (let i = 0; i < categories.length; i++) {
			categories[i].selected = "true";
		}

		let filters = this.state.filters;
		const index = filters.findIndex((x) => x.key == "Search");
		if (index === -1) {
			filters.push({
				key: "Search",
				property: query,
				dates: null,
				interval: null,
				list: null,
				name: "Cautare: " + query,
			});
		} else {
			filters[index].name = "Search: " + query;
			filters[index].property = query;
		}

		this.setState({ categories: categories, filters: filters }, () => {
			this._determineValidMarkers();
			this._refresh();
		});
	};

	private _addIntervalFilter = (
		label: string,
		property: string,
		interval: any
	) => {
		let categories = this.state.categories;
		for (let i = 0; i < categories.length; i++) {
			categories[i].selected = "true";
		}

		let filters = this.state.filters;
		const index = filters.findIndex((x) => x.key == label);
		if (index === -1) {
			filters.push({
				key: label,
				property: property,
				interval: interval,
				dates: null,
				list: null,
				name: label + ": " + interval.min + "-" + interval.max,
			});
		} else {
			filters[index].name = label + ": " + interval.min + "-" + interval.max;
			filters[index].interval = interval;
		}

		this.setState({ filters: filters }, () => {
			this._determineValidMarkers();
			this._refresh();
		});
	};

	private _addDateFilter = (label: string, property: string, dates: any) => {
		let categories = this.state.categories;
		for (let i = 0; i < categories.length; i++) {
			categories[i].selected = "true";
		}

		let filters = this.state.filters;
		const index = filters.findIndex((x) => x.key == label);
		if (index === -1) {
			filters.push({
				key: label,
				property: property,
				interval: null,
				dates: dates,
				list: null,
				name: label + ": " + dates.startShow + " - " + dates.endShow,
			});
		} else {
			filters[index].name =
				label + ": " + dates.startShow + " - " + dates.endShow;
			filters[index].dates = dates;
		}

		this.setState({ filters: filters }, () => {
			this._determineValidMarkers();
			this._refresh();
		});
	};

	private _addListFilter = (label: string, property: string, value: any) => {
		let categories = this.state.categories;
		for (let i = 0; i < categories.length; i++) {
			categories[i].selected = "true";
		}

		let filters = this.state.filters;
		const index = filters.findIndex((x) => x.key == label);
		if (index === -1) {
			filters.push({
				key: label,
				property: property,
				interval: null,
				list: [value],
				dates: null,
				name: label + ": " + value,
			});
		} else {
			if (!filters[index].list.includes(value)) {
				filters[index].list.push(value);
				filters[index].name += ", " + value;
			}
		}

		//console.log("Filtre adaugate", filters);

		this.setState({ filters: filters }, () => {
			this._determineValidMarkers();
			this._refresh();
		});
	};

	private _refresh = () => {
		if (this._refFiltersList) {
			this._refFiltersList.forceUpdate();
		}
		if (this._refCategoriesList) {
			this._refCategoriesList.forceUpdate();
		}
	};

	private hideDialog = () => {
		this.setState({ hideAuth: true });
	};

	private showDialog = () => {
		this.setState({ hideAuth: false });
	};
}
